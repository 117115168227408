@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  background: transparent;
  border: 2px solid #000000;
  border-top-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: rotate 1s infinite linear;
};

.spinner1 {
  background: transparent;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: rotate 1s infinite linear;
};
